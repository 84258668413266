import * as React from 'react';
import { graphql } from 'gatsby';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import HomeIcon from '../icons/buttons/pixfort-icon-home-7.svg';
import PixfortBuilderIcon from '../icons/buttons/pixfort-icon-pixfort-builder-1.svg';
import EssentialsLogo from '../images/items/essentials-thumbnail.png';
import EnvatoIcon from '../icons/pixfort-icon-envato-1.svg';
import PopupVideo from '../videos/popup-builder-new.mp4';

const SocialLinks = ({ data, location }) => {
	const siteTitle = data.site.siteMetadata.title;

	return (
		<Layout location={location} title={siteTitle}>
			<Seo title='Social Bio Links' />
			<div className=''>
				<main className='mx-auto max-w-7xl mt-24 px-4 sm:mt-24 sm:px-6 md:mt-36 lg:px-8'>
					<div className='text-center relative z-10'>
						<h1 className='max-w-lg inline-block text-4xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-5xl md:text-6xl'>
							<span className='block xl:inline leading-[1.3]'>
								Quick Links
							</span>
						</h1>
						<div>
							<p className='inline-block text-base font-light text-gray-400 sm:mx-auto sm:text-lg md:text-xl lg:mx-0 mt-2'>Select from the links below:</p>
						</div>


							<div className='flex justify-center mt-8'>
								<a href='https://core.pixfort.com/popup-builder' target='_blank' className='flex items-center max-w-sm mx-auto  w-full bg-white shadow-2xl shadow-slate-300/50 dark:shadow-black/20 border border-gray-200 dark:border-white/10 dark:bg-gray-800 p-5 rounded-xl'>
									{/* <img className='m-0 pr-4' height='100' width='100' src={EssentialsLogo} /> */}
									<video class="rounded-[25px] m-0 mr-6" width="84px" autoPlay muted playsinline loop>     
										<source src={PopupVideo} type="video/mp4" />
										Your browser does not support the video tag. 
									</video>
									<div className='flex flex-col'>
										<h3 className='text-base sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white text-left'>Popups Reimagined.</h3>
										<p className='text-sm font-semibold text-gray-600 dark:text-white/70 text-left mt-1.5 underline decoration-2 decoration-gray-300 dark:decoration-gray-600 underline-offset-[4px]'>Check What's New </p>
									</div>
								</a>
							</div>

							<div className='flex justify-center mt-4'>
								<a href='https://1.envato.market/Essentials' target='_blank' className='flex items-center max-w-sm mx-auto bg-white shadow-2xl shadow-slate-300/50 dark:shadow-black/20 border border-gray-200 dark:border-white/10 dark:bg-gray-800 p-5 rounded-xl'>
									<img className='m-0 mr-6' height='84' width='84' src={EssentialsLogo} />
									<h3 className='text-base sm:text-lg font-semibold tracking-tight text-gray-900 dark:text-white text-left'>Essentials Multipurpose WordPress Theme</h3>
								</a>
							</div>



						<div className='mt-5 flex justify-center max-w-sm mx-auto'>

								<a
									target='_blank'
									href='https://1.envato.market/P0PE6X'
									className='w-full justify-center text-gray-700 hover:text-gray-900 dark:text-white dark:hover:text-gray-300 py-3 px-5 bg-white dark:bg-white/20 text-sm font-semibold rounded-md focus:outline-none transition ease-in-out hover:-translate-y-0.5 duration-200 border border-black/5 dark:border-white/10'
								>
									<EnvatoIcon className='inline-block mr-2' />
									Our portfolio on Themeforest
								</a>

						</div>

						<div className='mt-5 flex justify-center max-w-sm mx-auto'>

								<Link
									to='/'
									className='w-full justify-center rounded-md border border-black/10 dark:border-white/20 bg-primary px-5 py-3 text-base font-semibold text-white hover:bg-primary/90 transition ease-in-out hover:-translate-y-1 duration-200 shadow-lg shadow-primary/25  focus:outline-none'
								>
									<HomeIcon className='inline-block mr-2' />
									Visit our website
								</Link>

						</div>


					</div>
				</main>
			</div>
		</Layout>
	);
};

export default SocialLinks;

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
			}
		}
	}
`;
